import React, { useState } from "react";
import { Box, Button, Fab, Fade, Typography } from "@mui/material";
import styled from "styled-components";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import SideDrawer from "./SideDrawer";
import MenuIcon from "@mui/icons-material/Menu";
// @ts-ignore
import MobileLogo from "../images/mobile-logo.jpeg";
import { useNavigate } from "react-router-dom";
import MovingText from "./MovingText";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import SwipeUpIcon from "@mui/icons-material/SwipeUp";

const StyledButton = styled(Button)`
  && {
    color: white;
    text-transform: none;
    white-space: nowrap;

    &:hover {
      background-color: transparent;
    }
  }
`;

const StyledTypography = styled(Typography)`
  && {
    font-family: "Protest Riot", sans-serif;
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  && {
    color: #e9b6ce;
    margin-right: -0.5rem;
  }
`;

const NavBar = () => {
  const { isDevice } = useScreenSizes();
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window,
    threshold: 100,
  });
  const handleScrollClick = () => {
    const anchor = document.querySelector("#top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling behavior
        block: "start", // Scroll to the top of the viewport
      });
    }
  };

  return (
    <>
      <Box>
        <Box
          display="grid"
          gridTemplateColumns={isDevice ? "0.5fr 1fr" : "0.5fr 1.5fr"}
          padding={isDevice ? "0.5rem" : "0.75rem"}
          overflow="hidden"
          bgcolor={"#000000"}
          gap={isDevice ? "0" : "1.3rem"}
        >
          {!isDevice && (
            <>
              <Box
                width="14rem"
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="0 auto"
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                }}
              >
                <img width="100%" src={MobileLogo} alt="Wavy fonts" />
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  fontWeight="700"
                  fontFamily="Indie Flower, cursive"
                  color="#e9b6ce"
                >
                  Pasta Bar & Cafe
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap="1rem"
                padding="0 1rem"
                width="50%"
                justifyContent="space-evenly"
              >
                <StyledButton variant="text" onClick={() => navigate("/menu")}>
                  <StyledTypography variant="subtitle1">MENU</StyledTypography>
                </StyledButton>
                <StyledButton
                  variant="text"
                  href="https://www.opentable.ca/r/dreamland-cafe-reservations-ottowa?restref=1063837&lang=en-CA&ot_source=Restaurant%20website"
                >
                  <StyledTypography variant="subtitle1">RESERVATIONS</StyledTypography>
                </StyledButton>

                <StyledButton variant="text" onClick={() => navigate("/contact")}>
                  <StyledTypography variant="subtitle1">CONTACT</StyledTypography>
                </StyledButton>
                <StyledButton variant="text" onClick={() => navigate("/catering")}>
                  <StyledTypography variant="subtitle1">CATERING</StyledTypography>
                </StyledButton>
              </Box>
            </>
          )}
          {isDevice && (
            <>
              <Box margin={"auto 0"} width="100%">
                <React.Fragment>
                  <Box width="6rem" display="flex" justifyContent="center">
                    <Button
                      startIcon={<StyledMenuIcon />}
                      onClick={() => setToggleDrawer(true)}
                      sx={{
                        outline: "2px solid #e9b6ce",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        borderRadius: "8px",
                        borderStyle: "solid",
                        opacity: "1",
                        borderShadow:
                          "rgba(255, 255, 255, 0.2) 0px 2px 0px 0px inset, rgba(0, 0, 0, 0.25) 0px -1px 0px 1px inset, rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      variant="contained"
                    />
                  </Box>
                  <SideDrawer toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} />
                </React.Fragment>
              </Box>
              <Box onClick={handleClick} margin="0 auto 0 2rem">
                <img width="100%" src={MobileLogo} alt="Wavy fonts" />
                <Typography
                  variant="body1"
                  textAlign="right"
                  fontWeight="700"
                  fontFamily="Indie Flower, cursive"
                  color="#e9b6ce"
                >
                  Pasta Bar & Cafe
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box
          width={"100%"}
          height="2rem"
          bgcolor="#e9b6ce"
          overflow={"hidden"}
          position={"relative"}
          display={"flex"}
          sx={{
            borderWidth: "0.2px",
            borderColor: "#686868",
            borderStyle: "solid",
          }}
        >
          <MovingText />
        </Box>
      </Box>
      <Fade in={trigger}>
        <Box
          onClick={handleScrollClick}
          role="presentation"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <Fab size="small" aria-label="scroll back to top" sx={{ backgroundColor: "#e9b6ce" }}>
            <SwipeUpIcon />
          </Fab>
        </Box>
      </Fade>
    </>
  );
};

export default NavBar;
